import { render, staticRenderFns } from "./HeaderNav.vue?vue&type=template&id=0a5b61a8&scoped=true"
import script from "./HeaderNav.vue?vue&type=script&lang=js"
export * from "./HeaderNav.vue?vue&type=script&lang=js"
import style0 from "./HeaderNav.vue?vue&type=style&index=0&id=0a5b61a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a5b61a8",
  null
  
)

export default component.exports