<template>
    <div class="outer">
        <div class="title__wrap">
            <h1>Вход и регистрация</h1>
        </div>
        <form @submit.prevent="validateEmail">
            <div class="inputs">
                <v-text-field
                    v-model="valueEmail"
                    label="Email"
                    :rules="emailRules"
                    @input="updateInput"
                    autocomplete="email"
                    autofocus
                    dense
                    outlined
                    class="email-field"
                    :class="{
                        'error-input': this.emailRules.length > 0,
                        'true-input': this.emailRules.length === 0 && emailTrue,
                    }"
                    required
                    clearable
                >
                </v-text-field>
            </div>
            <v-btn
                :loading="isLoading"
                depressed
                :color="'primary'"
                class="next-btn"
                block
                @click="validateEmail"
            >
                <div class="btn-content-container">Далее</div>
            </v-btn>
        </form>

        <div class="politic-checkbox">
            <input class="checkBox" type="checkbox" id="checkbox" required v-model="checked" />

            <p class="politic">
                Я принимаю условия
                <a href="https://cdn.gochamp.ru/pdf/agreement.pdf" target="_blank"
                    >пользовательского соглашения</a
                >
                и соглашаюсь с&nbsp;<a href="https://cdn.gochamp.ru/pdf/policy.pdf" target="_blank"
                    >политикой обработки персональных данных</a
                >
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: String, required: true },
        isLoading: { type: Boolean, required: true },
        validate: { type: Function, required: true },
    },
    data() {
        return {
            checked: false,
            valueEmail: this.value,
            prevValue: "",
            emailRules: [],
            emailTrue: false,
        };
    },
    methods: {
        validateEmail() {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (pattern.test(this.valueEmail)) {
                this.emailRules = [];

                if (!this.checked) {
                    this.emailRules = [
                        "Подтвердите согласие с политикой обработки персональных данных",
                    ];
                } else this.validate();
            } else {
                this.emailRules = ["проверьте email"];
            }
        },
        async spaceBlock() {
            await this.$nextTick();
        },
        async updateInput(event) {
            if (event.split("").includes(" ")) {
                await this.$nextTick();
                this.valueEmail = this.prevValue;
                this.emailRules = [];
            } else {
                await this.$nextTick();
                this.prevValue = this.valueEmail;
                await this.$nextTick();
                this.valueEmail = event;
                await this.$nextTick();
                this.$emit("input", event);
                this.emailRules = [];
            }
        },
    },
    watch: {
        valueEmail() {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (pattern.test(this.valueEmail)) {
                this.emailTrue = true;
            } else {
                this.emailTrue = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    width: 100%;
    margin: 88px 0 50px 0 !important;
    padding: 0 16px !important;
}

.title__wrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 54px;
}

.v-form h1 {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.005em;

    color: #1a1a1a;
}

.v-form,
.form {
    display: flex;
    flex-direction: column;
    // gap: 32px;
}

.v-form {
    background: #fff;
    width: 100%;
    border-radius: 10px;
}
.inputs {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 20px;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    margin-bottom: 6px !important;
}
.btn-content-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-icon {
    transform: rotate();
    margin-right: 10px;
    visibility: visible;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader-icon.hidden {
    visibility: hidden;
}

.next-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-top: 2px;
    margin-bottom: 12px;
}
.politic-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.politic {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;

    color: #868686;
}
.politic a {
    color: #868686;
}
.email-field .error--text {
    color: red !important;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
    .outer {
        max-width: 380px;
        width: 100%;
        align-self: center;
    }
    .title__wrap {
        margin-bottom: 30px;
    }

    .title__wrap h1 {
        font: 700 40px "PT Sans";
        text-align: center;
        line-height: 110%; /* 44px */
        letter-spacing: -0.2px;
    }

    .politic-checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .politic {
        text-align: left;
    }

    .politic a {
        font: 400 14px "Inter";
        line-height: 130%; /* 18.2px */
        letter-spacing: -0.21px;
    }
}
</style>
