<template>
    <div class="wrapper-profile">
        <div class="v-form">
            <h2>Ваши призы</h2>

            <div v-if="isLoading">Загрузка...</div>

            <div v-else-if="prizeList.length" class="table-wrapper">
                <div
                    v-for="{ id, prize, document_status } in prizeList"
                    :key="id"
                    class="table-body"
                >
                    <div class="table-line">
                        <p class="field-title">Приз: {{ convertPrizeName(prize) }}</p>
                        <v-btn
                            @click="
                                $router
                                    .push({ name: 'singlePrize', params: { id } })
                                    .catch(() => {})
                            "
                            >{{ convertDocumentStatus(document_status) }}</v-btn
                        >
                    </div>
                </div>
            </div>

            <p v-else>
                У вас еще нет присвоенных призов — участвуйте в акции и ваши призы появятся в этом
                разделе
            </p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            prizeList: "prizeList",
            isLoading: "isLoadingPrizeList",
        }),
    },
    methods: {
        ...mapActions({
            getUserPrize: "getUserPrize",
        }),

        convertPrizeName(prizeName) {
            switch (prizeName) {
                case "iPhone":
                    return "Apple iPhone 15 Pro Max";
                case "macBook":
                    return "Apple MacBook Pro 14";
                case "million":
                    return "1.000.000 Рублей";

                default:
                    return null;
            }
        },
        convertDocumentStatus(documentStatus) {
            switch (documentStatus) {
                case "notFilledIn":
                    return "Требуется заполнить реквизиты победителя";
                case "pending":
                    return "Документы не подписаны";
                case "completed":
                    return "Документы подписаны";

                default:
                    return null;
            }
        },
    },

    mounted() {
        this.getUserPrize();
    },
};
</script>

<style scoped>
.table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.table-body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    background-color: #f6f6f6;
    border-radius: 10px;
}

.table-line {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.field-title {
    display: flex;
    align-items: center;
    background-color: #dce4ec;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
}

.field-title-sended {
    display: flex;
    align-items: center;
    background-color: green;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
}

.field-value {
    overflow-x: auto;
    flex: 1;
    text-align: left;
}

.v-btn:not(.v-btn--round).v-size--default {
    margin-top: 0px;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
    .wrapper-profile {
        max-width: 800px;
        width: 100%;
    }

    .v-form {
        border-radius: 16px;
        padding: 60px;
    }
}
</style>
